









































































import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props) {
    const state = reactive({
      item: [
        {
          arrivalDateClose: false,
          leaveDateClose: false,
        },
      ],
    });

    const addItem = () => {
      props.value.push({
        firstName: undefined,
        lastName: undefined,
        roommate: undefined,
        arrival: undefined,
        leave: undefined,
      });
      state.item.push({
        arrivalDateClose: false,
        leaveDateClose: false,
      });
    };

    const removeItem = (index: number) => {
      props.value.splice(index, 1);
    };

    return { state, addItem, removeItem };
  },
});
